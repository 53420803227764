import React, { useEffect, useState } from "react";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/500.css";
import "./home.css";
import tik from "../../img/tik.png";
import hero from "../../img/hero.png";
import about from "../../img/about.png";
import hiring from "../../img/hiring.jpg";
import egCOm from "../../img/portfolio/eg_com.png";
import vssl from "../../img/portfolio/vssl.png";
import sonos from "../../img/portfolio/sonos.png";
import urc_project from "../../img/portfolio/urc.png";
import zee_project from "../../img/portfolio/zee.png";
import poly from "../../img/portfolio/poly.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRight,
  faBullseye,
  faGears,
  faCode,
  faPalette,
  faCloudBolt,
  faHeadset,
  faBarsProgress,
  faMagnifyingGlass,
  faPeopleCarryBox,
} from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "animate.css";
import zee from "../../img/clients/zee.png";
import urc from "../../img/clients/urc.png";
import speco from "../../img/clients/speco.png";
import sonons from "../../img/clients/sonons.png";
import avenview from "../../img/clients/avenview.png";
import connects from "../../img/clients/connects.png";
import Testimonial from "../../components/testimonial/Testimonial";
import { Link } from "react-router-dom";
import Rescue from "../../components/rescue/Rescue";
function Home(props) {
  const [clientLogosCount, setClientLogosCount] = useState(5);
  function handleClientLogosCount() {
    return window.screen.width < 600
      ? setClientLogosCount(3)
      : setClientLogosCount(5);
  }
  useEffect(() => {
    handleClientLogosCount();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: clientLogosCount,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
  };
  return (
    <>
      <div className="vector" />
      <section
        className={`hero ${
          props.darkMode && "hero_dark"
        } animate__animated animate__fadeIn`}
      >
        <div className="left">
          <div className="hero_vector"></div>
          <div className="hero_content">
            <h3 className="sub_heading">Hello,</h3>
            <h1 className="heading">
              <span>Welcome to Capra</span> Your Ultimate Software Solution
            </h1>
            <p>
              A talented team to help you in your journey on creating useful and
              easy to use product
            </p>
            <div>
              <Link to="/contact" onClick={props.handleLoader}>
                <button className="primary-btn">
                  Let's Talk
                  <FontAwesomeIcon className="message_icon" icon={faMessage} />
                </button>
              </Link>
              <Link
                to="/services"
                onClick={props.handleLoader}
                className={`${
                  props.darkMode ? "primary-link-dark" : "primary-link"
                }`}
              >
                {" "}
                Check our services
                <FontAwesomeIcon
                  className="arrow_icon"
                  icon={faArrowRight}
                  beat
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="right">
          <img className="hero_img" src={hero} alt="" />
          <img className="tik" src={tik} alt="" />
        </div>
      </section>
      <section className={`clients ${props.darkMode && "dark"}`}>
        <h2>Client We Helped</h2>
        <Slider {...settings} className="slider">
          <div className="slide">
            <img src={zee} alt="zee" />
          </div>
          <div className="slide">
            <img src={urc} alt="urc" />
          </div>
          <div className="slide">
            <img src={speco} alt="speco" />
          </div>
          <div className="slide">
            <img src={sonons} alt="sonos" />
          </div>
          <div className="slide">
            <img src={avenview} alt="avenview" />
          </div>
          <div className="slide">
            <img src={connects} alt="connects" />
          </div>
        </Slider>
      </section>
      <section className={`about ${props.darkMode && "dark"}`}>
        <h1>
          Elevating Businesses with Cutting-edge <br />
          <span>Software Solutions</span>
        </h1>
        <div className="content">
          <div className="left" data-aos="fade-right" data-aos-duration="1000">
            <img src={about} alt="about" />
          </div>
          <div className="right" data-aos="fade-left" data-aos-duration="1500">
            <h3>
              What Capra Can Do<span>?</span>
            </h3>
            <p>
              Are you seeking a cutting-edge software solution that streamlines
              your business operations and empowers growth? Look no further than
              Capra, your trusted partner in harnessing the true potential of
              technology. We specialize in providing tailor-made software
              solutions that revolutionize your business processes, enhance
              productivity, and drive unparalleled success.
            </p>
            <div>
              <Link to="/contact" onClick={props.handleLoader}>
                <button className="primary-btn">
                  Let's Talk
                  <FontAwesomeIcon className="message_icon" icon={faMessage} />
                </button>
              </Link>
              <Link
                to="/about"
                onClick={props.handleLoader}
                className={`${
                  props.darkMode ? "primary-link-dark" : "primary-link"
                }`}
              >
                {" "}
                More about us
                <FontAwesomeIcon
                  className="arrow_icon"
                  icon={faArrowRight}
                  beat
                />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className={`hiring ${props.darkMode && "dark"}`}>
        <div className="headings">
          <h1>Maximize Efficiency, Minimize Expenses</h1>
          <h2>Save Time and Money</h2>
        </div>
        <p>
          Unlock the full potential of the tech world's finest talents
          effortlessly. Embrace excellence as you onboard expert developers,
          designers, and engineers with ease.
        </p>
        <div className="cards">
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={hiring} />
            <div className="content">
              <h5>Hire a</h5>
              <h3>Web Developer</h3>
            </div>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={hiring} />
            <div className="content">
              <h5>Hire a</h5>
              <h3>App Developer</h3>
            </div>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={hiring} />
            <div className="content">
              <h5>Hire a</h5>
              <h3>QA Engineer</h3>
            </div>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={hiring} />
            <div className="content">
              <h5>Hire a</h5>
              <h3>AI Engineer</h3>
            </div>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={hiring} />
            <div className="content">
              <h5>Hire a</h5>
              <h3>Digital Marketer</h3>
            </div>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={hiring} />
            <div className="content">
              <h5>Hire a</h5>
              <h3>UI/UX Designer</h3>
            </div>
          </div>
        </div>
      </section>
      <section className={`services ${props.darkMode && "dark"}`}>
        <div className="content">
          <h2>The Services We Offers</h2>
          <h1>Discover the Range of Services We Provide</h1>
          <div className="row">
            <div className="col">
              <FontAwesomeIcon icon={faCode} className="service-icon" />
              <h3>Custom Software Development</h3>
              <p>
                We excel in creating bespoke software solutions that address
                your specific business challenges....
              </p>
              <Link to="/services" onClick={props.handleLoader}>
                <button className="secondary-btn">
                  Learn More
                  <FontAwesomeIcon
                    className="arrow_icon"
                    icon={faArrowRight}
                    beat
                  />
                </button>
              </Link>
            </div>
            <div className="col">
              <FontAwesomeIcon icon={faGears} className="service-icon" />
              <h3>System Integration</h3>
              <p>
                Seamlessly integrating disparate software systems is crucial for
                smooth and efficient business operations...
              </p>
              <Link to="/services" onClick={props.handleLoader}>
                <button className="secondary-btn">
                  Learn More
                  <FontAwesomeIcon
                    className="arrow_icon"
                    icon={faArrowRight}
                    beat
                  />
                </button>
              </Link>
            </div>
            <div className="col">
              <FontAwesomeIcon icon={faPalette} className="service-icon" />
              <h3>UI/UX Design</h3>
              <p>
                We believe that user experience is paramount in driving software
                adoption and user satisfaction. Our talented designers...
              </p>
              <Link to="/services" onClick={props.handleLoader}>
                <button className="secondary-btn">
                  Learn More
                  <FontAwesomeIcon
                    className="arrow_icon"
                    icon={faArrowRight}
                    beat
                  />
                </button>
              </Link>
            </div>
            <div className="col">
              <FontAwesomeIcon icon={faCloudBolt} className="service-icon" />
              <h3>Cloud-Based Solutions</h3>
              <p>
                Embrace the power of the cloud with our cloud-based software
                solutions. Our team leverages leading cloud platforms...
              </p>
              <Link to="/services" onClick={props.handleLoader}>
                <button className="secondary-btn">
                  Learn More
                  <FontAwesomeIcon
                    className="arrow_icon"
                    icon={faArrowRight}
                    beat
                  />
                </button>
              </Link>
            </div>
            <div className="col">
              <FontAwesomeIcon icon={faHeadset} className="service-icon" />
              <h3>Software Maintenance & Support</h3>
              <p>
                We understand that software requires continuous maintenance and
                support to ensure optimal performance....
              </p>
              <Link to="/services" onClick={props.handleLoader}>
                <button className="secondary-btn">
                  Learn More
                  <FontAwesomeIcon
                    className="arrow_icon"
                    icon={faArrowRight}
                    beat
                  />
                </button>
              </Link>
            </div>
            <div className="col">
              <FontAwesomeIcon icon={faBullseye} className="service-icon" />
              <h3>Consulting & Strategy</h3>
              <p>
                With our in-depth industry knowledge and technical expertise, we
                offer consulting and strategy services to help...
              </p>
              <Link to="/services" onClick={props.handleLoader}>
                <button className="secondary-btn">
                  Learn More
                  <FontAwesomeIcon
                    className="arrow_icon"
                    icon={faArrowRight}
                    beat
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className={`projects ${props.darkMode && "dark"}`}>
        <h2>The work we realized</h2>
        <h1>The work we did that made our Client happy and satisfied</h1>
        <div className="row">
          <div className="col">
            <img src={egCOm} />
            <div className="content">
              <h3 className="category">Wordpress Development</h3>
              <p>EG Com</p>
            </div>
          </div>
          <div className="col">
            <img src={vssl} />
            <div className="content">
              <h3 className="category">Shopify Development</h3>
              <p>Vssl</p>
            </div>
          </div>
          <div className="col">
            <img src={sonos} />
            <div className="content">
              <h3 className="category">Next.js Development</h3>
              <p>Sonos</p>
            </div>
          </div>
          <div className="col">
            <img src={urc_project} />
            <div className="content">
              <h3 className="category">Woocommerce Development</h3>
              <p>URC</p>
            </div>
          </div>
          <div className="col">
            <img src={zee_project} />
            <div className="content">
              <h3 className="category">Wordpress Development</h3>
              <p>Zeevee</p>
            </div>
          </div>
          <div className="col">
            <img src={poly} />
            <div className="content">
              <h3 className="category">Web Development</h3>
              <p>Poly</p>
            </div>
          </div>
        </div>
        <div className="btn-div">
          <Link to="/projects" onClick={props.handleLoader}>
            <button
              className={`${
                props.darkMode ? "secondary-btn-dark" : "secondary-btn"
              } `}
            >
              Explore More Projects{" "}
              <FontAwesomeIcon
                className="arrow_icon"
                icon={faArrowRight}
                beat
              />
            </button>
          </Link>
        </div>
      </section>
      <section className={`spiner ${props.darkMode && "dark"}`}>
        <div className="content">
          <div className="row">
            <div className="col">
              <div className="spin_vector right"></div>
              <div className="spin_content right">
                <h3>1000+</h3>
                <p>Projects Completed</p>
              </div>
              <div className="spin-div right">
                <div className="icon">
                  <FontAwesomeIcon icon={faBarsProgress} />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="spin_vector top"></div>
              <div className="spin_content top">
                <h3>1000+</h3>
                <p>Positive Reviews</p>
              </div>
              <div className="spin-div top">
                <div className="icon">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
              </div>
              <div className="spin_vector bottom"></div>
              <div className="spin_content bottom">
                <h3>1000+</h3>
                <p>Happy Clients</p>
              </div>
              <div className="spin-div bottom">
                <div className="icon">
                  <FontAwesomeIcon icon={faFaceSmile} />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="spin_vector left"></div>
              <div className="spin_content left">
                <h3>99%</h3>
                <p>Customer Satisfaction</p>
              </div>
              <div className="spin-div left">
                <div className="icon">
                  <FontAwesomeIcon icon={faPeopleCarryBox} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial darkMode={props.darkMode} />
      <Rescue darkMode={props.darkMode} handleLoader={props.handleLoader} />
    </>
  );
}

export default Home;
