import React, { useState, useEffect } from "react";
import avatar from "../../img/hiring.jpg";
import devin from "../../img/testimonial/devin.jpg";
import robert from "../../img/testimonial/robert.jpg";
import changkpark from "../../img/testimonial/chang-k-park.jpg";
import Brian from "../../img/testimonial/Brian_Hinman.png";
import Kim from "../../img/testimonial/Jaeseung-Kim.jpg";
import gallay from "../../img/testimonial/gallay.jpg";
import spence from "../../img/testimonial/spence.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./testimonial.css";

function Testimonial(props) {
  const [testimonialCount, setTestimonialCount] = useState(5);
  function handleTestimonialCount() {
    return window.screen.width < 600
      ? setTestimonialCount(1)
      : setTestimonialCount(2);
  }
  useEffect(() => {
    handleTestimonialCount();
  }, []);
  const setting = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: testimonialCount,
    slidesToScroll: 1,
  };
  return (
    <section className={`testimonial ${props.darkMode && 'dark'}`}>
      <h2>Testimonal</h2>
      <h1>
        You still hesitate about working with us? <br /> Check what They sat
        about us
      </h1>
      <Slider {...setting} className="slider">
        <div className="slide">
          <div className="card">
            <p>
              Working with Capra Digital has been a game-changer for our
              business. Their innovative solutions and dedicated team
              transformed our online presence, boosting engagement and
              conversions. They truly understand our needs and deliver beyond
              expectations.
            </p>
            <div className="person_details">
              <img src={devin} alt="Avatar" width="90" />
              <div>
                <p>
                  <span>Devin Davies</span>
                </p>
                <p>CRO VSSL</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="card">
            <p>
              Capra Digital's expertise turned our vision into reality. Their
              commitment to quality, attention to detail, and seamless
              collaboration made the entire process a breeze. Our project's
              success owes much to their creative approach and technical
              finesse.
            </p>
            <div className="person_details">
              <img src={robert} alt="Avatar" width="90" />
              <div>
                <p>
                  <span>Robert Michaels</span>
                </p>
                <p>CEO Zeevee</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="card">
            <p>
              Capra Digital is more than a service provider; they're a strategic
              partner. Their solutions not only elevated our brand but also
              improved user experiences. Their team's responsiveness and
              proactive suggestions showcase their dedication to client success.
            </p>
            <div className="person_details">
              <img src={changkpark} alt="Avatar" width="90" />
              <div>
                <p>
                  <span>Chang K. Park</span>
                </p>
                <p>CEO URC</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="card">
            <p>
              Choosing Capra Digital was a game-changer. Their personalized
              approach and deep understanding of our industry needs set them
              apart. The impact of their work on our digital presence and
              customer interactions has been remarkable.
            </p>
            <div className="person_details">
              <img src={Brian} alt="Avatar" width="90" />
              <div>
                <p>
                  <span>Brian Hinman</span>
                </p>
                <p>CEO POLY</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="card">
            <p>
              Capra Digital's team combines creativity with technical expertise,
              resulting in solutions that stand out. Their ability to capture
              our brand essence and translate it into a compelling digital
              experience exceeded our expectations.
            </p>
            <div className="person_details">
              <img src={Kim} alt="Avatar" width="90" />
              <div>
                <p>
                  <span>Jaeseung Kim</span>
                </p>
                <p>President LG Electronics</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="card">
            <p>
              Capra Digital is our go-to for digital solutions. Their dedication
              to delivering value shines through in every project. Their team's
              professionalism, timely delivery, and consistent results make them
              a partner we can rely on.
            </p>
            <div className="person_details">
              <img src={gallay} alt="Avatar" width="90" />
              <div>
                <p>
                  <span>Emmanuelle Gallay</span>
                </p>
                <p>Founder EG Com</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="card">
            <p>
              Capra Digital's approach is truly client-centric. They not only
              met our requirements but also brought fresh ideas to the table,
              enhancing our project's outcomes. Their commitment to excellence
              is evident in everything they do.
            </p>
            <div className="person_details">
              <img src={spence} alt="Avatar" width="90" />
              <div>
                <p>
                  <span>Patrick Spence</span>
                </p>
                <p>CEO Sonos</p>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
}

export default Testimonial;
