import React from 'react'
import logo from '../../img/icon.png'
import 'animate.css';
import './loader.css'
function Loader(props) {
  return (
    <div className={`loader ${props.darkMode && "loader_dark"}`}>
      <img className='animate__animated animate__flip' src={logo} alt='logo'/>
    </div>
  )
}

export default Loader