import React from "react";
import "./services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import project from "../img/project.png";
import services from "../img/services.png";
import tik from "../img/tik.png";
import Slider from "react-slick";
import Testimonial from "../components/testimonial/Testimonial";
import Java from "../img/Services/Java.png";
import { Link } from "react-router-dom";
import Rescue from "../components/rescue/Rescue";
function Services(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
  };
  return (
    <>
      <section className={`hero service ${props.darkMode && 'hero_dark'} animate__animated animate__fadeIn`}>
        <div className="left">
          <div className="service_vector"></div>
          <div className="hero_content">
            <h3 className="sub_heading">Our Services,</h3>
            <h1 className="heading">
              <span>Services</span> We Offers
            </h1>
            <p>
              Capra is your trusted partner in software development, offering a
              comprehensive range of programming languages and frameworks to
              meet your unique business needs.
            </p>
            <div>
              <Link to="/contact" onClick={props.handleLoader}>
                <button className="primary-btn">
                  Let's Talk
                  <FontAwesomeIcon className="message_icon" icon={faMessage} />
                </button>
              </Link>
              <Link to="/projects" onClick={props.handleLoader}
                className={`${
                  props.darkMode ? "primary-link-dark" : "primary-link"
                }`}
              >
                {" "}
                Check our projects
                <FontAwesomeIcon
                  className="arrow_icon"
                  icon={faArrowRight}
                  beat
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="right">
          <img className="hero_img" src={services} alt="" />
          <img className="tik" src={tik} alt="" />
        </div>
      </section>
      <section className={`service process ${props.darkMode && "dark"}`}>
        <h2>Our Process</h2>
        <div className="heading_container">
          <h1>
            Our Working Process help you to get the best from our team skill
          </h1>
          <p>
            With Our team knowledge of different process We Choose the perfect
            process to suit the Development of your Product
          </p>
        </div>
        <div className="process_container">
          <div className="row">
            <div className="col">
              <h2>
                <span>01</span>User Research
              </h2>
              <p>
                User research helps us find out exactly how our target customers
                feel when interacting with a product
              </p>
            </div>
            <div className="col">
              <h2>
                <span>02</span>Design
              </h2>
              <p>
                User research helps us find out exactly how our target customers
                feel when interacting with a product
              </p>
            </div>
            <div className="col">
              <h2>
                <span>03</span>Test
              </h2>
              <p>
                User research helps us find out exactly how our target customers
                feel when interacting with a product
              </p>
            </div>
            <div className="col">
              <h2>
                <span>04</span>Implementation
              </h2>
              <p>
                User research helps us find out exactly how our target customers
                feel when interacting with a product
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="vector" />
      <section className={`service category ${props.darkMode && "dark"}`}>
        <div className="row" id="development">
          <h1>Development</h1>
          <img src={tik} className="tik" />
          <Slider {...settings} className="slider">
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Java Development</h1>
                  <p>
                    Capra excels in Java development, harnessing the power of
                    this versatile and widely adopted programming language. Our
                    experienced Java developers build robust and scalable
                    enterprise-level applications, utilizing frameworks such as
                    Spring and Hibernate. With our expertise in Java, we ensure
                    high-performance, secure, and efficient solutions that meet
                    your specific business requirements
                  </p>
                </div>
                <div className="col col-img">
                  <img src={Java} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>PHP Development</h1>
                  <p>
                    Capra offers top-notch PHP development services, leveraging
                    this popular scripting language for dynamic and interactive
                    web applications. Our PHP developers utilize frameworks like
                    Laravel and CodeIgniter to deliver secure, scalable, and
                    feature-rich web solutions. From e-commerce platforms to
                    content management systems, our PHP expertise enables us to
                    meet your diverse web development needs.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Python Development</h1>
                  <p>
                    Capra harnesses the versatility of Python to create powerful
                    and efficient software solutions. Our Python developers
                    utilize frameworks like Django and Flask to build web
                    applications, data analytics tools, and automation scripts.
                    Python's simplicity, readability, and vast library ecosystem
                    make it an ideal choice for rapid application development
                    and data processing.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>JavaScript and Node.js Development</h1>
                  <p>
                    Capra excels in JavaScript and Node.js development, enabling
                    the creation of highly interactive and scalable web
                    applications. Our expertise in modern JavaScript frameworks
                    like React and Angular allows us to deliver rich user
                    experiences and seamless front-end development. With
                    Node.js, we build efficient and high-performance back-end
                    solutions, catering to real-time applications and
                    microservices architecture.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>NET Development</h1>
                  <p>
                    Capra offers comprehensive .NET development services,
                    leveraging the power of this robust framework. Our .NET
                    developers build secure and scalable enterprise
                    applications, utilizing frameworks like ASP.NET and .NET
                    Core. With .NET, we enable seamless integration, rapid
                    development, and efficient deployment of software solutions
                    tailored to your business requirements.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>WordPress Development</h1>
                  <p>
                    Capra specializes in WordPress development, creating
                    feature-rich and customizable websites and applications. Our
                    WordPress experts utilize the vast array of themes, plugins,
                    and customization options to build engaging and visually
                    appealing online platforms. With WordPress, we enable easy
                    content management, SEO optimization, and seamless website
                    administration for your digital presence.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="row" id="mobile_development">
          <h1>Mobile Development</h1>
          <img src={tik} className="tik" />
          <Slider {...settings} className="slider">
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>iOS App Development</h1>
                  <p>
                    Capra excels in iOS app development, harnessing the power of
                    Apple's platform to create seamless and visually appealing
                    mobile experiences. Our experienced iOS developers leverage
                    Swift and Objective-C programming languages to build robust
                    and feature-rich applications. From concept to submission on
                    the App Store, we ensure strict adherence to Apple's
                    guidelines, providing secure and engaging iOS apps that
                    cater to your target audience.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Android App Development</h1>
                  <p>
                    Capra offers top-notch Android app development services,
                    tapping into the vast potential of the Android ecosystem.
                    Our skilled developers utilize Java and Kotlin programming
                    languages to build high-quality, scalable, and versatile
                    Android applications. We follow best practices and ensure
                    compatibility with a wide range of Android devices,
                    delivering user-friendly apps that capture the essence of
                    the Android platform.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Cross-Platform App Development</h1>
                  <p>
                    Capra understands the value of reaching a broader audience
                    with a single codebase. That's why we specialize in
                    cross-platform app development using frameworks like React
                    Native and Flutter. Our cross-platform experts create apps
                    that run smoothly on both iOS and Android devices, providing
                    a cost-effective solution without compromising on
                    performance or user experience. With cross-platform
                    development, we help you save time and resources while
                    reaching a wider user base.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>UI/UX Design and Optimization</h1>
                  <p>
                    Capra recognizes the significance of captivating user
                    experiences and intuitive interfaces. Our UI/UX designers
                    work closely with our developers to create visually
                    appealing and user-friendly mobile apps. We focus on
                    optimizing the user journey, ensuring seamless navigation
                    and interactive elements that engage and delight users. By
                    prioritizing UI/UX design, we enhance user satisfaction,
                    increase retention, and drive positive brand experiences.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="row" id="marketing">
          <h1>Marketing</h1>
          <img src={tik} className="tik" />
          <Slider {...settings} className="slider">
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Digital Marketing Strategy</h1>
                  <p>
                    Capra specializes in crafting comprehensive digital
                    marketing strategies that align with your business
                    objectives. Our experts conduct in-depth market research,
                    analyze consumer behavior, and identify target audiences to
                    develop effective strategies. We leverage various digital
                    channels, including search engine optimization (SEO), social
                    media marketing, email marketing, content marketing, and
                    paid advertising, to enhance brand visibility, drive website
                    traffic, and generate qualified leads.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Data Analytics and Insights</h1>
                  <p>
                    Capra harnesses the power of data analytics to provide
                    actionable insights for informed decision-making. Our team
                    utilizes advanced tools and techniques to analyze marketing
                    performance, customer behavior, and campaign effectiveness.
                    By measuring key performance indicators (KPIs), tracking
                    conversions, and conducting A/B testing, we optimize
                    marketing strategies, identify opportunities for
                    improvement, and ensure maximum ROI.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Search Engine Optimization (SEO)</h1>
                  <p>
                    Capra excels in optimizing websites for search engines,
                    ensuring higher visibility and organic traffic. Our SEO
                    specialists conduct thorough keyword research, optimize
                    on-page and off-page elements, and implement technical SEO
                    strategies to improve website rankings. By enhancing website
                    authority, optimizing content, and improving user
                    experience, we help businesses reach their target audience
                    and drive qualified organic traffic.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Social Media Marketing</h1>
                  <p>
                    Capra leverages the power of social media to create
                    meaningful connections and drive engagement. Our social
                    media experts develop customized strategies to build brand
                    awareness, engage target audiences, and foster customer
                    loyalty. We create compelling content, manage social media
                    campaigns, and monitor performance to ensure maximum impact
                    on platforms such as Facebook, Instagram, Twitter, LinkedIn,
                    and more.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Content Marketing</h1>
                  <p>
                    Capra emphasizes the importance of content marketing in
                    establishing thought leadership and driving brand authority.
                    Our content strategists develop and implement content
                    marketing plans that resonate with your target audience. We
                    create valuable and engaging content in various formats,
                    including blog articles, infographics, videos, and eBooks,
                    to attract, educate, and nurture leads throughout the
                    customer journey.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="row" id="data_science">
          <h1>Data Science</h1>
          <img src={tik} className="tik" />
          <Slider {...settings} className="slider">
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Data Exploration and Analysis</h1>
                  <p>
                    Capra's data scientists excel in exploring and analyzing
                    large datasets to uncover patterns, trends, and
                    correlations. By employing advanced statistical models and
                    machine learning algorithms, we transform raw data into
                    meaningful insights. Whether it's customer behavior
                    analysis, market research, or performance evaluation, our
                    data exploration and analysis services provide businesses
                    with the knowledge to drive growth and stay ahead of the
                    competition.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Predictive Analytics</h1>
                  <p>
                    Capra leverages predictive analytics to forecast future
                    outcomes and make proactive decisions. By analyzing
                    historical data and identifying relevant variables, we build
                    predictive models that enable businesses to anticipate
                    trends, mitigate risks, and identify growth opportunities.
                    Our predictive analytics solutions empower organizations to
                    optimize marketing campaigns, enhance operational
                    efficiency, and improve resource allocation, ultimately
                    driving better business outcomes.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Machine Learning</h1>
                  <p>
                    Capra harnesses the power of machine learning to develop
                    intelligent systems that can learn from data and make
                    accurate predictions. Our data scientists employ various
                    machine learning algorithms, including supervised and
                    unsupervised learning, to automate tasks, detect anomalies,
                    and uncover valuable insights. By leveraging machine
                    learning, businesses can streamline processes, personalize
                    customer experiences, and optimize decision-making based on
                    data-driven recommendations.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Data Visualization</h1>
                  <p>
                    Capra recognizes the importance of data visualization in
                    effectively communicating insights. Our data scientists
                    utilize advanced visualization techniques and tools to
                    present complex data in intuitive and visually appealing
                    formats. By transforming raw data into interactive charts,
                    graphs, and dashboards, we enable businesses to gain a
                    comprehensive understanding of their data, facilitating
                    better decision-making and driving actionable outcomes.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="row" id="qa">
          <h1>Quality Assurance</h1>
          <img src={tik} className="tik" />
          <Slider {...settings} className="slider">
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Test Planning and Strategy</h1>
                  <p>
                    Capra's quality assurance experts collaborate with your
                    development team to devise comprehensive test plans and
                    strategies. We meticulously analyze project requirements,
                    define test objectives, and develop test cases and scenarios
                    to ensure comprehensive coverage. By aligning testing
                    efforts with your business goals, we ensure that all
                    critical functionalities and user scenarios are thoroughly
                    tested.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Functional Testing</h1>
                  <p>
                    Capra performs rigorous functional testing to verify that
                    your software meets the specified requirements and functions
                    as intended. Our QA team conducts systematic tests,
                    including unit testing, integration testing, and system
                    testing, to identify and rectify any functional defects. By
                    ensuring that your software operates flawlessly, we enhance
                    user experience and minimize the risk of issues impacting
                    end-users.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Performance Testing</h1>
                  <p>
                    Capra emphasizes the importance of performance testing to
                    ensure your software can handle real-world usage scenarios.
                    Our testing experts conduct load testing, stress testing,
                    and scalability testing to assess your application's
                    performance under different workloads. By simulating high
                    user traffic and analyzing response times, resource usage,
                    and system bottlenecks, we help optimize your software's
                    performance and deliver a seamless user experience.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Security Testing</h1>
                  <p>
                    Capra prioritizes security testing to identify
                    vulnerabilities and safeguard your software from potential
                    threats. Our QA specialists perform comprehensive security
                    assessments, including penetration testing and vulnerability
                    scanning, to ensure that your application is secure against
                    unauthorized access and data breaches. By addressing
                    security concerns proactively, we protect your software and
                    instill trust in your users.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>User Acceptance Testing (UAT)</h1>
                  <p>
                    Capra conducts user acceptance testing to validate that your
                    software meets end-users' expectations and requirements. We
                    collaborate closely with stakeholders to define test
                    criteria and scenarios that reflect real-world usage. By
                    involving end-users in the testing process, we gain valuable
                    feedback and ensure that your software delivers the intended
                    functionality and user experience.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="row" id="designing">
          <h1>Designing</h1>
          <img src={tik} className="tik" />
          <Slider {...settings} className="slider">
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Graphic Design</h1>
                  <p>
                    Capra's graphic designers are skilled in crafting visually
                    stunning and effective designs that align with your brand
                    identity. From logo design and branding materials to
                    marketing collateral and digital assets, we create designs
                    that capture the essence of your business and engage your
                    target audience. Our designers employ the latest industry
                    trends, color schemes, typography, and visual elements to
                    ensure a cohesive and impactful visual representation of
                    your brand.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Web Design</h1>
                  <p>
                    Capra's web designers specialize in creating exceptional
                    user experiences through intuitive and visually appealing
                    website designs. We combine aesthetics with functionality to
                    deliver websites that reflect your brand's personality and
                    provide seamless navigation for visitors. Our designers
                    consider responsive design principles, user interface (UI)
                    best practices, and user experience (UX) optimization to
                    ensure that your website stands out in the digital landscape
                    and drives meaningful interactions with your audience.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>UI/UX Design</h1>
                  <p>
                    Capra places great emphasis on UI/UX design to create
                    intuitive and user-centric interfaces across digital
                    platforms. Our UI/UX designers conduct extensive user
                    research, wireframing, and prototyping to craft designs that
                    enhance usability and deliver exceptional user experiences.
                    We strive to create interfaces that are visually appealing,
                    accessible, and optimized for conversions, ensuring that
                    your audience engages with your digital products
                    effortlessly.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="content">
                <div className="col">
                  <h1>Branding and Identity Design</h1>
                  <p>
                    Capra's designers specialize in crafting unique and cohesive
                    branding identities that reflect your brand values and
                    resonate with your target audience. We work closely with you
                    to understand your brand's essence, mission, and vision,
                    translating them into memorable visual elements. From logo
                    design and color palettes to brand guidelines and visual
                    assets, our branding and identity design services help
                    establish a strong and consistent brand presence across all
                    touchpoints.
                  </p>
                </div>
                <div className="col col-img">
                  <img src={project} />
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      <Testimonial darkMode={props.darkMode} />
      <Rescue darkMode={props.darkMode} handleLoader={props.handleLoader}/>
    </>
  );
}

export default Services;
