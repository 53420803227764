import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Outlet } from "react-router-dom";
import Loader from "../components/loader/Loader";

function Main(props) {

  return (
    <>
      {props.loader ? (
        <Loader  darkMode={props.darkMode}/>
      ) : (
        <>
          <Header handleLoader={props.handleLoader} DarkModeToggle={props.DarkModeToggle} darkMode={props.darkMode}/>
          <Outlet/>
          <Footer handleLoader={props.handleLoader} darkMode={props.darkMode}/>
        </>
      )}
    </>
  );
}

export default Main;
