import React, { useRef } from "react";
import "./header.css";
import { useState, useEffect } from "react";
import black_logo from "../../img/black_logo.png";
import white_logo from "../../img/white_logo.png";
import Java from "../../img/Icons/Java.png";
import Php from "../../img/Icons/PHP.png";
import Python from "../../img/Icons/python.png";
import Node from "../../img/Icons/Node js.png";
import Net from "../../img/Icons/net.png";
import Wordpress from "../../img/Icons/Wordpress.png";
import IOS_icon from "../../img/Icons/ios.png";
import android_icon from "../../img/Icons/android.png";
import cross_platform from "../../img/Icons/Cross platform.png";
import ui_ux from "../../img/Icons/UI UX.png";
import digital_marketing from "../../img/Icons/digital marketing.png";
import ppc from "../../img/Icons/PPC Expert.png";
import seo from "../../img/Icons/SEO.png";
import social_media from "../../img/Icons/social media.png";
import content_marketing from "../../img/Icons/Content writing.png";
import data_explore from "../../img/Icons/Data exploration.png";
import predictive_analytics from "../../img/Icons/predivtive analysis.png";
import machine_learning from "../../img/Icons/Machine learning.png";
import data_visualization from "../../img/Icons/data visualization.png";
import test_planning from "../../img/Icons/test planning.png";
import functional_testing from "../../img/Icons/Functional testing.png";
import performance_testing from "../../img/Icons/performance Testing.png";
import security_testing from "../../img/Icons/security Testing.png";
import UAT_icon from "../../img/Icons/UAT.png";
import graphic_design from "../../img/Icons/Graphic designer.png";
import web_design from "../../img/Icons/Web design.png";
import branding from "../../img/Icons/branding.png";
import Blog_icon from "../../img/Icons/Blog.png";
import Portfolio_icon from "../../img/Icons/Portfolio.png";
import About_icon from "../../img/Icons/What we do.png";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faXmark,
  faMoon,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
function Header(props) {
  const [scrolly, setScrolly] = useState(0);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const isMouseInModal = useRef(false);
  const isMouseInCompanyModal = useRef(false);
  const [homeActive, setHomeActive] = useState(false);
  const [serviceActive, setServiceActive] = useState(false);
  const [ContactActive, setContactActive] = useState(false);
  const [CompanyActive, setCompanyActive] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [serviceModalMobile, setServiceModalMobile] = useState(false);
  function handleServiceModalMobile() {
    return window.screen.width < 600
      ? setServiceModalMobile(true)
      : setServiceModalMobile(false);
  }
  const location = useLocation();
  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 50) setScrolly(true);
    else setScrolly(false);
  };

  useEffect(() => {
    handleServiceModalMobile();
    switch (location.pathname) {
      case "/":
        setHomeActive(true);
        setServiceActive(false);
        setContactActive(false);
        setCompanyActive(false);
        break;
      case "/services":
        setHomeActive(false);
        setServiceActive(true);
        setContactActive(false);
        setCompanyActive(false);
        break;
      case "/contact":
        setHomeActive(false);
        setServiceActive(false);
        setContactActive(true);
        setCompanyActive(false);
        break;
      case "/about":
        setHomeActive(false);
        setServiceActive(false);
        setContactActive(false);
        setCompanyActive(true);
        break;
      case "/projects":
        setHomeActive(false);
        setServiceActive(false);
        setContactActive(false);
        setCompanyActive(true);
        break;
      case "/blog":
        setHomeActive(false);
        setServiceActive(false);
        setContactActive(false);
        setCompanyActive(true);
        break;
      default:
        setHomeActive(false);
        setServiceActive(false);
        setContactActive(false);
        setCompanyActive(false);
        break;
    }
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  const handleServiceModal = (e) => {
    setShowServiceModal(true);
  };

  const handleCompanyModal = (e) => {
    setShowCompanyModal(true);
  };

  const handleModalMouseLeave = () => {
    setTimeout(() => {
      if (!isMouseInModal.current) {
        setShowServiceModal(false);
      }
    }, 100);
  };

  const handleCompanyModalMouseLeave = () => {
    setTimeout(() => {
      if (!isMouseInCompanyModal.current) {
        setShowCompanyModal(false);
      }
    }, 100);
  };

  const handleDarkModeToggle = () => {
    props.DarkModeToggle();
  };

  return (
    <header
      className={`header ${props.darkMode && "header_dark"} ${
        scrolly && "scrolly"
      }`}
    >
      <div className="logo_div">
        <Link to="/" onClick={props.handleLoader}>
          <img src={props.darkMode ? white_logo : black_logo} alt="logo" />
        </Link>
      </div>
      <div className="mode_icon">
        <FontAwesomeIcon
          onClick={handleDarkModeToggle}
          icon={props.darkMode ? faSun : faMoon}
        />
      </div>
      <div className="nav_icon" onClick={() => setShowNav(true)}>
        <FontAwesomeIcon icon={faBarsStaggered} />
      </div>
      <nav className={`navbar ${showNav && "show"}`}>
        <div className="close_mark">
          <FontAwesomeIcon icon={faXmark} onClick={() => setShowNav(false)} />
        </div>
        <ul>
          <li>
            <Link
              to="/"
              className={`${homeActive && "active"}`}
              onClick={props.handleLoader}
            >
              Home
            </Link>
            <hr className={`${homeActive && "active"}`} />
          </li>
          <li>
            <Link
              to={`${serviceModalMobile ? "/services" : "#"}`}
              onMouseEnter={handleServiceModal}
              onMouseLeave={handleModalMouseLeave}
              className={`${serviceActive && "active"}`}
              onClick={serviceModalMobile && props.handleLoader}
            >
              Our Services
            </Link>
            <hr className={`${serviceActive && "active"}`} />
          </li>
          {showServiceModal && (
            <div
              className="service_modal"
              data-aos="fade-up"
              onMouseEnter={(e) => {
                isMouseInModal.current = true;
              }}
              onMouseLeave={(e) => {
                isMouseInModal.current = false;
                setShowServiceModal(false);
              }}
            >
              <div className="row">
                <div className="ch">
                  <h3>DEVELOPMENT</h3>
                </div>
                <HashLink
                  to="/services#development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={Java} alt="java" />
                    <p>Java</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={Php} alt="java" />
                    <p>PHP</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={Python} alt="java" />
                    <p>Python</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={Node} alt="java" />
                    <p>Node.Js</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={Net} alt="java" />
                    <p>.NET</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={Wordpress} alt="java" />
                    <p>Wordpress</p>
                  </div>
                </HashLink>
                <div className="ch">
                  <h3>MOBILE DEVELOPMENT</h3>
                </div>
                <HashLink
                  to="/services#mobile_development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={IOS_icon} alt="java" />
                    <p>IOS</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#mobile_development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={android_icon} alt="java" />
                    <p>Android</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#mobile_development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={cross_platform} alt="java" />
                    <p>Cross Platform</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#mobile_development"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={ui_ux} alt="java" />
                    <p>UI/UX Optimization</p>
                  </div>
                </HashLink>
                <div className="ch">
                  <h3>MARKETING</h3>
                </div>
                <HashLink to="/services#marketing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={digital_marketing} alt="java" />
                    <p>Digital Marketing</p>
                  </div>
                </HashLink>
                <HashLink to="/services#marketing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={ppc} alt="java" />
                    <p>PPC</p>
                  </div>
                </HashLink>
                <HashLink to="/services#marketing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={seo} alt="java" />
                    <p>SEO</p>
                  </div>
                </HashLink>
                <HashLink to="/services#marketing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={social_media} alt="java" />
                    <p>Social Media</p>
                  </div>
                </HashLink>
                <HashLink to="/services#marketing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={content_marketing} alt="java" />
                    <p>Content Marketing</p>
                  </div>
                </HashLink>
                <div className="ch sceince">
                  <h3>DATA SCEINCE</h3>
                </div>
                <HashLink
                  to="/services#data_science"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={data_explore} alt="java" />
                    <p>Data Exploration</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#data_science"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={predictive_analytics} alt="java" />
                    <p>Predictive Analytics</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#data_science"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={machine_learning} alt="java" />
                    <p>Machine Learning</p>
                  </div>
                </HashLink>
                <HashLink
                  to="/services#data_science"
                  onClick={props.handleLoader}
                >
                  <div className="col">
                    <img src={data_visualization} alt="java" />
                    <p>Data Visualization</p>
                  </div>
                </HashLink>
                <div className="ch">
                  <h3>Quality Assurance</h3>
                </div>
                <HashLink to="/services#qa" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={test_planning} alt="java" />
                    <p>Test Planning</p>
                  </div>
                </HashLink>
                <HashLink to="/services#qa" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={functional_testing} alt="java" />
                    <p>Functional Testing</p>
                  </div>
                </HashLink>
                <HashLink to="/services#qa" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={performance_testing} alt="java" />
                    <p>Performance Testing</p>
                  </div>
                </HashLink>
                <HashLink to="/services#qa" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={security_testing} alt="java" />
                    <p>Security Testing</p>
                  </div>
                </HashLink>
                <HashLink to="/services#qa" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={UAT_icon} alt="java" />
                    <p>UAT</p>
                  </div>
                </HashLink>
                <div className="ch">
                  <h3>DESIGNING</h3>
                </div>
                <HashLink to="/services#designing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={graphic_design} alt="java" />
                    <p>Graphic Design</p>
                  </div>
                </HashLink>
                <HashLink to="/services#designing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={web_design} alt="java" />
                    <p>Web Design</p>
                  </div>
                </HashLink>
                <HashLink to="/services#designing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={ui_ux} alt="java" />
                    <p>UI/UX Design</p>
                  </div>
                </HashLink>
                <HashLink to="/services#designing" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={branding} alt="java" />
                    <p>Branding</p>
                  </div>
                </HashLink>
              </div>
            </div>
          )}
          <li>
            <Link
              to="#"
              onMouseEnter={handleCompanyModal}
              onMouseLeave={handleCompanyModalMouseLeave}
              className={`${CompanyActive && "active"}`}
            >
              Company
            </Link>
            <hr className={`${CompanyActive && "active"}`} />
          </li>
          {showCompanyModal && (
            <div
              className="company_modal"
              data-aos="fade-up"
              onMouseEnter={(e) => {
                isMouseInCompanyModal.current = true;
              }}
              onMouseLeave={(e) => {
                isMouseInCompanyModal.current = false;
                setShowCompanyModal(false);
              }}
            >
              <div className="row">
                <Link to="/about" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={About_icon} alt="java" />
                    <p>Who Are We?</p>
                  </div>
                </Link>
                <Link to="/projects" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={Portfolio_icon} alt="java" />
                    <p>Projects</p>
                  </div>
                </Link>
                <Link to="/blog" onClick={props.handleLoader}>
                  <div className="col">
                    <img src={Blog_icon} alt="java" />
                    <p>Blog</p>
                  </div>
                </Link>
              </div>
            </div>
          )}
          <li>
            <Link
              to="/contact"
              onClick={props.handleLoader}
              className={`${ContactActive && "active"}`}
            >
              Contact Us
            </Link>
            <hr className={`${ContactActive && "active"}`} />
          </li>
        </ul>
      </nav>
      <div className="btn_div">
        <FontAwesomeIcon
          onClick={handleDarkModeToggle}
          icon={props.darkMode ? faSun : faMoon}
        />
        <Link to="/contact" onClick={props.handleLoader}>
          <button className="primary-btn">Hire Now</button>
        </Link>
      </div>
    </header>
  );
}

export default Header;
