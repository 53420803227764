import React from "react";
import "./rescue.css";
import { Link } from "react-router-dom";
function Rescue(props) {
  return (
    <section className={`rescue ${props.darkMode && "dark"}`}>
      <div className="content">
        <div className="main">
          <h1>Rescue your company’s growth!</h1>
          <p>
            Let us know about your business plans on an introductory call, and
            we’ll lead the matching process.
          </p>
        </div>
        <div>
          <Link to="/contact" onClick={props.handleLoader}>
            <button className="primary-btn">Hire Capra</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Rescue;
