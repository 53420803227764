import React from "react";
import "./about.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faFaceSmile } from "@fortawesome/free-regular-svg-icons";
import tik from "../../img/tik.png";
import about from "../../img/about/about.png";
import ceo from "../../img/about/ceo.jpg";
import work1 from "../../img/about/work1.jpg";
import work2 from "../../img/about/work2.jpg";
import work3 from "../../img/about/work3.jpg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faBarsProgress,
  faMagnifyingGlass,
  faPeopleCarryBox,
} from "@fortawesome/free-solid-svg-icons";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import Testimonial from "../../components/testimonial/Testimonial";
import { Link } from "react-router-dom";
import Rescue from "../../components/rescue/Rescue";
function About(props) {
  return (
    <>
      <div className="vector" />
      <section className={`hero ${props.darkMode && 'hero_dark'} animate__animated animate__fadeIn`}>
        <div className="left">
          <div className="about_vector"></div>
          <div className="hero_content">
            <h3 className="sub_heading">About Us,</h3>
            <h1 className="heading">
              <span>Who Are</span> We?
            </h1>
            <p>
              A talented team to help you in your journey on creating useful and
              easy to use product
            </p>
            <div>
              <Link to="/contact" onClick={props.handleLoader}>
                <button className="primary-btn">
                  Let's Talk
                  <FontAwesomeIcon className="message_icon" icon={faMessage} />
                </button>
              </Link>
              <Link to="/services" onClick={props.handleLoader}
                className={`${
                  props.darkMode ? "primary-link-dark" : "primary-link"
                }`}
              >
                {" "}
                Check our services
                <FontAwesomeIcon
                  className="arrow_icon"
                  icon={faArrowRight}
                  beat
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="right">
          <img className="hero_img" src={about} alt="" />
          <img className="tik" src={tik} alt="" />
        </div>
      </section>
      <section className={`milestone ${props.darkMode && 'dark'}`}>
        <div className="heading_container">
          <h1>OUR JOURNEY</h1>
        </div>
        <div className="content_container">
          <div className="cards">
            <div className="card">
              <div className="top">
                <h1>2019</h1>
              </div>
              <div className="bottom">
                <p>CAPRA DIGITAL, AUSTRALIA, 25 EMPLOYEES</p>
              </div>
            </div>
            <div className="card">
              <div className="top">
                <h1>2020</h1>
              </div>
              <div className="bottom">
                <p>CAPRA DIGITAL, PAKISTAN, 50 EMPLOYEES</p>
              </div>
            </div>
            <div className="card">
              <div className="top">
                <h1>2021</h1>
              </div>
              <div className="bottom">
                <p>CAPRA DIGITAL, 105 EMPLOYEES</p>
              </div>
            </div>
            <div className="card">
              <div className="top">
                <h1>2022</h1>
              </div>
              <div className="bottom">
                <p>CAPRA DIGITAL,UNITED KINGDOM, 200 EMPLOYEES</p>
              </div>
            </div>
            <div className="card">
              <div className="top">
                <h1>2023</h1>
              </div>
              <div className="bottom">
                <p>PARTNERSHIP, CAPRA BOLD</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`ceo ${props.darkMode && 'dark'}`}>
        <div className="left">
          <h2>CEO & CTO</h2>
          <h1>USAMA ZUBAIR</h1>
          <p>
            Meet “Usama Zubair”, the visionary driving Capra Digital's success.
            With a passion for innovation and a commitment to excellence, Usama
            leads our team in shaping digital solutions that inspire and
            transform. Join us in celebrating the driving force behind our
            journey.
          </p>
          <p>
            Usama Zubair is a visionary leader with
            extensive experience in the software industry. Under their guidance,
            capra digital has witnessed remarkable growth and success, expanding
            its offerings and forging strategic partnerships to deliver
            exceptional software solutions to clients globally
          </p>
          <div className="about_social social-media-container">
            <ul>
              <li>
                <FaFacebookF size={25} fontWeight={"normal"} color="#228CC9" />
              </li>
              <li>
                <FaInstagram size={25} fontWeight={"normal"} color="#228CC9" />
              </li>
              <li>
                <FaTwitter size={25} fontWeight={"normal"} color="#228CC9" />
              </li>
              <li>
                <FaLinkedinIn size={25} fontWeight={"normal"} color="#228CC9" />
              </li>
            </ul>
          </div>
        </div>
        <div className="right">
          <img src={ceo} alt="ceo"/>
        </div>
      </section>
      <section className={`work_environment ${props.darkMode && 'dark'}`}>
        <div className="heading_container">
          <h1>Our Work Environment</h1>
          <p>
            Capra's work environment thrives on innovation and collaboration.
            With flexible workspaces, diverse perspectives, and regular
            knowledge-sharing, we foster creativity and effective
            problem-solving. Our focus on work-life balance and employee
            well-being reflects our commitment to a supportive and successful
            community.
          </p>
        </div>
        <div className="images_container">
          <img src={work1} alt="work1"/>
          <img src={work2} alt="work2"/>
          <img src={work3} alt="work3"/>
        </div>
      </section>
      <section className={`spiner ${props.darkMode && "dark"}`}>
        <div className="content">
          <div className="row">
            <div className="col">
              <div className="spin_vector right"></div>
              <div className="spin_content right">
                <h3>1000+</h3>
                <p>Projects Completed</p>
              </div>
              <div className="spin-div right">
                <div className="icon">
                  <FontAwesomeIcon icon={faBarsProgress} />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="spin_vector top"></div>
              <div className="spin_content top">
                <h3>1000+</h3>
                <p>Positive Reviews</p>
              </div>
              <div className="spin-div top">
                <div className="icon">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </div>
              </div>
              <div className="spin_vector bottom"></div>
              <div className="spin_content bottom">
                <h3>1000+</h3>
                <p>Happy Clients</p>
              </div>
              <div className="spin-div bottom">
                <div className="icon">
                  <FontAwesomeIcon icon={faFaceSmile} />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="spin_vector left"></div>
              <div className="spin_content left">
                <h3>99%</h3>
                <p>Customer Satisfaction</p>
              </div>
              <div className="spin-div left">
                <div className="icon">
                  <FontAwesomeIcon icon={faPeopleCarryBox} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial darkMode={props.darkMode} />
      <Rescue darkMode={props.darkMode} handleLoader={props.handleLoader}/>
    </>
  );
}

export default About;
