import React from "react";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import tik from "../../img/tik.png";
import contact from "../../img/contact.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import Rescue from "../../components/rescue/Rescue";
function Contact(props) {
  return (
    <>
      <section className={`hero ${props.darkMode && 'hero_dark'} animate__animated animate__fadeIn`}>
        <div className="left">
          <div className="contact_vector"></div>
          <div className="hero_content">
            <h3 className="sub_heading">Contact Us,</h3>
            <h1 className="heading">
              <span>Get In Touch</span> With Us
            </h1>
            <p>
              Partner with our skilled team to bring your user-friendly product
              to life. Get in touch with us today!
            </p>
            <div>
              <HashLink to="#contact_form">
                <button className="primary-btn">
                  Let's Talk
                  <FontAwesomeIcon className="message_icon" icon={faMessage} />
                </button>
              </HashLink>
              <Link to="/services" onClick={props.handleLoader}
                className={`${
                  props.darkMode ? "primary-link-dark" : "primary-link"
                }`}
              >
                {" "}
                Check our services
                <FontAwesomeIcon
                  className="arrow_icon"
                  icon={faArrowRight}
                  beat
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="right">
          <img className="hero_img" src={contact} alt="" />
          <img className="tik" src={tik} alt="" />
        </div>
      </section>
      <section className={`contact_card ${props.darkMode && 'dark'}`} id="contact_form">
        <div className="main_contact">
          <div className="contact-info">
            <h1>
              Contact <span>Information</span>
            </h1>
            <div className="frame">
              <div className="frame-content">
                <h3>Headquarter</h3>
                <p>3 9RhondaCres,Truganina 3029 ,VictoriaMelbourne</p>
              </div>
              <div className="frame-content">
                <h3>Australian ABN</h3>
                <p>006-147-039-4788</p>
                <h3>Pakistani ABN</h3>
                <p>0092-310-6065-176</p>
                <br />
                <p>info@capradigital.com.au</p>
              </div>
            </div>
            <div className="social-media-container">
              <ul>
                <li>
                  <FaFacebookF
                    size={25}
                    fontWeight={"normal"}
                    color="#228CC9"
                  />
                </li>
                <li>
                  <FaInstagram
                    size={25}
                    fontWeight={"normal"}
                    color="#228CC9"
                  />
                </li>
                <li>
                  <FaTwitter size={25} fontWeight={"normal"} color="#228CC9" />
                </li>
                <li>
                  <FaLinkedinIn
                    size={25}
                    fontWeight={"normal"}
                    color="#228CC9"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <form id="contact-form">
              <div className="input_box">
                <label>Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="input_box">
                <label>Your Contact Number</label>
                <input
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter Contact"
                  required
                />
              </div>
              <div className="input_box">
                <label>Your Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="input_box">
                <label>Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Enter your message"
                  rows="4"
                  required
                ></textarea>
              </div>
              <button className="contact primary-btn" type="submit">
                Send
              </button>
            </form>
          </div>
        </div>
      </section>
      {/* <Rescue darkMode={props.darkMode} handleLoader={props.handleLoader}/> */}
    </>
  );
}

export default Contact;
