import React from "react";
import black_logo from "../../img/black_logo.png";
import white_logo from "../../img/white_logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaPaperPlane,
} from "react-icons/fa";
import "./footer.css";
function Footer(props) {
  return (
    <footer className={`${props.darkMode && 'dark'}`}>
      <div className="content">
        <div className="col col-1">
          <div className="logo_div">
            <img src={props.darkMode ? white_logo : black_logo} alt="logo" />
          </div>
          <p>
            Capra Digital is a Digital agencay that create User centred Product
            that help her client to evolve
          </p>
        </div>
        <div className="col col-2">
          <h3>Explore</h3>
          <ul>
            <li>Who are we?</li>
            <li>Our Services</li>
            <li>Our Projects</li>
            <li>Hire Capra</li>
            <li>Contact Us</li>
          </ul>
        </div>
        <div className="col col-2">
          <h3>Services</h3>
          <ul>
            <li>Web Development</li>
            <li>Mobile Development</li>
            <li>AI/ML</li>
            <li>DevSecOps</li>
            <li>Digital Marketing</li>
          </ul>
        </div>
        <div className="col col-4">
          <h3>Get in touch with us</h3>
          <p>Need Answers? Need help ? Just email us </p>
          <div>
            <input />
            <FaPaperPlane size={25} fontWeight={"normal"} color="#228CC9" />
          </div>
        </div>
      </div>
      <div className="content row2">
        <div className="col col-1">
          <h3>Headquarter</h3>
          <p>3 9RhondaCres,Truganina 3029 ,VictoriaMelbourne</p>
        </div>
        <div className="col col-1">
          <h3>Australian ABN</h3>
          <p>006-147-039-4788</p>
        </div>
        <div className="col col-1">
          <h3>Pakistani ABN</h3>
          <p>0092-310-6065-176</p>
          <p>info@capradigital.com.au</p>
        </div>
        <div className="col col-3">
          <h3>Follow Us</h3>
          <ul>
            <li>
              <FaFacebookF size={25} />
            </li>
            <li>
              <FaInstagram size={25} />
            </li>
            <li>
              <FaTwitter size={25} />
            </li>
            <li>
              <FaLinkedinIn size={25} />
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        Copyright © 2023 Capra Digital. All rights reserved
      </div>
    </footer>
  );
}

export default Footer;
