import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Main from "./pages/Main";
import "aos/dist/aos.css";
import Services from "./pages/Services";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Projects from "./pages/Projects";
import Blog from "./pages/blog/Blog";
import { useState,useEffect } from "react";
import AOS from "aos";
import React from "react";

function App() {
  const [darkMode,setDarkMode] = useState(false);
  const DarkModeToggle=()=>{
      setDarkMode(!darkMode);
      document.body.classList.toggle("dark");
  }
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
    AOS.init();
  },[]);

  const handleLoader=()=>{
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route to="/" element={<Main DarkModeToggle={DarkModeToggle} darkMode={darkMode} handleLoader={handleLoader} loader={loader}/>}>
          <Route index element={<Home darkMode={darkMode} handleLoader={handleLoader}/>}></Route>
          <Route path="services" element={<Services darkMode={darkMode} handleLoader={handleLoader}/>}></Route>
          <Route path="contact" element={<Contact darkMode={darkMode} handleLoader={handleLoader}/>}></Route>
          <Route path="about" element={<About darkMode={darkMode} handleLoader={handleLoader}/>}></Route>
          <Route path="projects" element={<Projects darkMode={darkMode} handleLoader={handleLoader}/>}></Route>
          <Route path="blog" element={<Blog darkMode={darkMode} handleLoader={handleLoader}/>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
