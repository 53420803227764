import React from "react";
import "./projects.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import tik from "../img/tik.png";
import projects from "../img/portfolio/projects.png";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import egCOm from "../img/portfolio/eg_com.png";
import vssl from "../img/portfolio/vssl.png";
import sonos from "../img/portfolio/sonos.png";
import urc_project from "../img/portfolio/urc.png";
import zee_project from "../img/portfolio/zee.png";
import poly from "../img/portfolio/poly.png";
import Testimonial from "../components/testimonial/Testimonial";
import { Link } from "react-router-dom";
import Rescue from "../components/rescue/Rescue";
function Projects(props) {
  return (
    <>
      <div className="vector" />
      <section className={`hero ${props.darkMode && 'hero_dark'} animate__animated animate__fadeIn`}>
        <div className="left">
          <div className="projects_vector"></div>
          <div className="hero_content">
            <h3 className="sub_heading">Our Projects,</h3>
            <h1 className="heading">
              <span>The Work </span>We Realized
            </h1>
            <p>
              We're proud of what we've accomplished – the things we made real.
              It's a reminder that hard work pays off and dreams can come true.
            </p>
            <div>
              <Link to="/contact" onClick={props.handleLoader}>
                <button className="primary-btn">
                  Let's Talk
                  <FontAwesomeIcon className="message_icon" icon={faMessage} />
                </button>
              </Link>
              <Link to="/services" onClick={props.handleLoader}
                className={`${
                  props.darkMode ? "primary-link-dark" : "primary-link"
                }`}
              >
                {" "}
                Check our services
                <FontAwesomeIcon
                  className="arrow_icon"
                  icon={faArrowRight}
                  beat
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="right">
          <img className="hero_img" src={projects} alt="" />
          <img className="tik" src={tik} alt="" />
        </div>
      </section>
      <section className={`projects_section ${props.darkMode && 'dark'}`}>
        <div className="project row_reverse">
          <div className="left">
            <h1>EG Com</h1>
            <p>
              EGComm is a dynamic communication solutions provider, offering
              cutting-edge services to businesses. Our portfolio showcases
              seamless integration of AI-driven systems and network
              infrastructure
            </p>
            <div className="category">
              <p>Wordpress Development</p>
            </div>
          </div>
          <div className="right">
            <img src={egCOm} />
          </div>
        </div>
        <div className="project">
          <div className="left">
            <h1>VSSL</h1>
            <p>
              VSSL's impressive portfolio showcases a multitude of innovative
              projects across various sectors. From web and mobile app
              development to AI-driven solutions. our expertise is evident in
              delivering cutting-edge technology.
            </p>
            <div className="category">
              <p>Shopify Development</p>
            </div>
          </div>
          <div className="right">
            <img src={vssl} />
          </div>
        </div>
        <div className="project row_reverse">
          <div className="left">
            <h1>Sonos</h1>
            <p>
              Sonos' portfolio exemplifies a legacy of excellence in audio
              technology. Discover our range of cutting-edge speakers,
              soundbars, and smart home audio solutions that redefine the way
              you experience music.
            </p>
            <div className="category">
              <p>Next.js Development</p>
            </div>
          </div>
          <div className="right">
            <img src={sonos} />
          </div>
        </div>
        <div className="project">
          <div className="left">
            <h1>URC Automation</h1>
            <p>
              Our automation portfolio showcases a diverse range of innovative
              solutions designed to streamline processes and boost
              efficiency.From robotic process automation (RPA) to smart
              manufacturing systems, our expertise enables businesses to
              optimize workflows and reduce operational costs.
            </p>
            <div className="category">
              <p>Woocommerce</p>
            </div>
          </div>
          <div className="right">
            <img src={urc_project} />
          </div>
        </div>
        <div className="project row_reverse">
          <div className="left">
            <h1>Zeevee</h1>
            <p>
              ZeeVee's impressive portfolio showcases a wide array of advanced
              video distribution solutions. From AV over IP to HD-SDI solutions,
              our expertise enables seamless content delivery for various
              industries.
            </p>
            <div className="category">
              <p>Wordpress Development</p>
            </div>
          </div>
          <div className="right">
            <img src={zee_project} />
          </div>
        </div>
        <div className="project">
          <div className="left">
            <h1>Poly</h1>
            <p>
              Poly's portfolio is a testament to our expertise in transformative
              communication solutions. Discover our cutting-edge audio and video
              conferencing devices, designed for seamless collaboration and
              connectivity.
            </p>
            <div className="category">
              <p>Web Development</p>
            </div>
          </div>
          <div className="right">
            <img src={poly} />
          </div>
        </div>
      </section>
      <Testimonial darkMode={props.darkMode} />
      <Rescue darkMode={props.darkMode} handleLoader={props.handleLoader}/>
    </>
  );
}

export default Projects;
