import React from "react";
import "./blog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import tik from "../../img/tik.png";
import blog from "../../img/blog/blog.png";
import blog1 from "../../img/blog/blog1.jpg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function Blog(props) {
  return (
    <>
      <section className={`hero ${props.darkMode && 'hero_dark'} animate__animated animate__fadeIn`}>
        <div className="left">
          <div className="blog_vector"></div>
          <div className="hero_content">
            <h3 className="sub_heading">Blogs,</h3>
            <h1 className="heading">
              <span>Dive into the World of </span>Capra Blogs
            </h1>
            <p>
              Capra Blogs offering a diverse range of thought-provoking
              perspectives that enrich minds and spark meaningful discussions.
            </p>
            <div>
              <Link to="/contact" onClick={props.handleLoader}>
                <button className="primary-btn">
                  Let's Talk
                  <FontAwesomeIcon className="message_icon" icon={faMessage} />
                </button>
              </Link>
              <Link to="/services" onClick={props.handleLoader}
                className={`${
                  props.darkMode ? "primary-link-dark" : "primary-link"
                }`}
              >
                {" "}
                Check our services
                <FontAwesomeIcon
                  className="arrow_icon"
                  icon={faArrowRight}
                  beat
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="right">
          <img className="hero_img" src={blog} alt="" />
          <img className="tik" src={tik} alt="" />
        </div>
      </section>
      <section className={`blogs ${props.darkMode && 'dark'}`}>
        <div className="blog">
          <img src={blog1} alt="blog1"></img>
          <div className="blog_content">
            <h1>Java vs C++</h1>
            <p>by dev | Apr,10 2023</p>
            <p>When it comes to coding languages, Java and C+..</p>
            <div>
              <button className="primary-btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="blog">
          <img src={blog1} alt="blog1"></img>
          <div className="blog_content">
            <h1>Java vs C++</h1>
            <p>by dev | Apr,10 2023</p>
            <p>When it comes to coding languages, Java and C+..</p>
            <div>
              <button className="primary-btn">Read More</button>
            </div>
          </div>
        </div>
        <div className="blog">
          <img src={blog1} alt="blog1"></img>
          <div className="blog_content">
            <h1>Java vs C++</h1>
            <p>by dev | Apr,10 2023</p>
            <p>When it comes to coding languages, Java and C+..</p>
            <div>
              <button className="primary-btn">Read More</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
